import React from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import HomeHeroBanner from './HomeHeroBanner'
import CreditCardDebtSimple from './CreditCardDebtSimple'
import DebtReliefPartners from './DebtReliefPartners'
import MoreQuestions from './MoreQuestions'
import ProgramWorks from './ProgramWorks'
import AsFeaturedIn from '../../shared/BrandPages/AsFeaturedIn'
import Faqs from 'src/components/pages/Home/Faqs'
import IconStrip from './HomeHeroBanner/IconStrip'
import StaticTrustMarkersWrapper from '../../shared/StaticTrustMarkersWrapper'
import DebtReliefCalculator from './DebtReliefCalculator'
import { numberFormat, getPhoneNumber } from 'src/common/utils'
import { useTrustMarkers } from 'src/common/trust-markers'

const Variant8 = ({ data, isDefaultLang }) => {
  const {
    heroBanner,
    debtSimple,
    programWorks,
    partners,
    featuredInData,
    trustMarkersCombination,
    debtReliefCalculator,
    cfosData,
    isCfosDisclousuresVariationActive = false,
  } = data

  const { getTrustMarkerById } = useTrustMarkers()
  const trustPilot = getTrustMarkerById('truspilot')

  const heroBannerContent = {
    ...heroBanner.content,
    trustPilot: {
      rating: Number(trustPilot?.rating),
      totalReviews: numberFormat(Number(trustPilot?.totalReview)),
      link: trustPilot?.link,
      color: '#33b083',
    },
    newTrustpilotItem: true,
  }

  return (
    <div className="w-content mx-auto">
      <HomeHeroBanner
        title={heroBanner.title}
        text={heroBanner.text}
        name={heroBanner.name}
        content={heroBannerContent}
        isDefaultLang={isDefaultLang}
        nameSuperscript="¹"
      />
      <IconStrip iconStrip={heroBanner.iconStrip} />
      <StaticTrustMarkersWrapper
        trustMarkersCombination={trustMarkersCombination}
        title="Freedom debt relief is trusted by 1 million clients and counting"
      />
      <LazyLoad offset={145}>
        <DebtReliefCalculator
          data={{
            ...debtReliefCalculator,
            buttonText: 'Get a free evaluation',
            showWarningSection: !isCfosDisclousuresVariationActive,
            title: 'You could free up ${amount} on your monthly payment',
          }}
          initialAmount={25000}
          cfosData={cfosData}
          isCfosDisclousuresVariationActive
        />
      </LazyLoad>
      <CreditCardDebtSimple
        title={debtSimple.title}
        debtTitle={debtSimple.debtTitle}
        debtText={debtSimple.debtText}
        saveTitle={debtSimple.saveTitle}
        saveText={debtSimple.saveText}
        enrollTitle={debtSimple.enrollTitle}
        enrollText={debtSimple.enrollText}
        footer={debtSimple.footer}
        button={debtSimple.button}
      />
      <ProgramWorks
        title={programWorks.title}
        content={programWorks.content}
        steps={programWorks.steps}
        footer={programWorks.footer}
      />
      <div className="bg-gray-130 pb-8 pt-12">
        <AsFeaturedIn items={featuredInData} />
      </div>
      {isDefaultLang ? (
        <>
          <DebtReliefPartners title={partners.title} text={partners.text} />
          <div className="flex items-center justify-center bg-[#EFF5FF] lg:bg-[#EFF5FF]">
            <Faqs />
          </div>
          <MoreQuestions phoneNumber={getPhoneNumber(false, false)} />
        </>
      ) : null}
    </div>
  )
}

Variant8.defaultProps = {
  isDefaultLang: true,
}

Variant8.propTypes = {
  data: PropTypes.object.isRequired,
  isDefaultLang: PropTypes.bool,
}

export default Variant8
