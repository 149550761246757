import React from 'react'

const StaticTrustMarkersWrapper = ({ title, trustMarkersCombination = [] }) => (
  <div className="px-6 pt-8 pb-2 lg:pt-12 lg:pb-6">
    {title && (
      <p className="text-center text-sm font-bold uppercase tracking-[.25em] text-[#5c708ad9]">
        {title}
      </p>
    )}
    <div className="px-8 lg:px-0">
      <div className="flex flex-col items-center justify-center lg:flex-row lg:py-6">
        {trustMarkersCombination.trustMarker.map((trustMarker) => (
          <div
            key={trustMarker.fields.id}
            className="w-full border-b-[1.5px] border-[#c0cbd8] py-6 last:border-[transparent] lg:w-auto lg:border-b-[0px] lg:border-r-[1.5px] lg:px-8 lg:py-3"
          >
            <img
              className="mx-auto h-[73px] lg:h-[84px]"
              src={'https:' + trustMarker.fields.image.fields.file.url}
              alt={trustMarker.fields.alt}
            />
          </div>
        ))}
      </div>
    </div>
  </div>
)

export default StaticTrustMarkersWrapper
